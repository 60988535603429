import Axios from 'axios';

const useRemoteApi = process.env.NEXT_PUBLIC_USE_REMOTE_API === 'true';
const isProd = process.env.NODE_ENV === 'production';

const RemoteApiUrl = 'https://dev-api.soubul.com/v1';
const ProdApiUrl = 'https://dev-api.soubul.com/v1';
//const DevApiUrl = 'http://localhost:5001/v1';
const DevApiUrl = 'https://dev-api.soubul.com/v1';

const axios = Axios.create({
  baseURL: useRemoteApi ? RemoteApiUrl : isProd ? ProdApiUrl : DevApiUrl,
});

export interface NetworkError {
  readonly code?: string;
  readonly message?: string;
}

export function parseAxiosErrorMessage(e: any): NetworkError {
  if (e.response && e.response.data && e.response.data.error) {
    return e.response.data.error;
  }
  return {};
}

axios.interceptors.request.use((config) => {
  const tk = typeof localStorage != 'undefined' && localStorage.getItem('tk');
  if (typeof tk === 'string') {
    config.headers['Authorization'] = `Bearer ${tk}`;
  }
  config.headers['X-App-Id'] = process.env.NEXT_PUBLIC_SOUBUL_APP_ID;
  config.headers['X-Api-Key'] = process.env.NEXT_PUBLIC_SOUBUL_API_KEY;
  config.withCredentials = true;
  return config;
});

export default axios;
