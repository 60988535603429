import {createContext, useContext} from 'react';
import type {ComponentType} from 'react';

export enum AlertType {
  Info,
  Success,
  Warning,
  Error,
}

export interface Alert {
  id: string;
  type: AlertType;
  message: string;
}

interface AlertsContextProps {
  readonly alerts: Alert[];
  readonly info: (msg: string, timeout?: number) => void;
  readonly success: (msg: string, timeout?: number) => void;
  readonly warning: (msg: string, timeout?: number) => void;
  readonly error: (msg: string, timeout?: number) => void;
}

export const AlertsContext = createContext<AlertsContextProps>({
  alerts: [],
  info: () => 0,
  success: () => 0,
  warning: () => 0,
  error: () => 0,
});

export interface WithAlertsProps {
  readonly alerts: AlertsContextProps;
}

type ExcludeAlertsProps<P> = Pick<P, Exclude<keyof P, keyof WithAlertsProps>>;

export function withAlerts<P extends WithAlertsProps>(
  ComposedComponent: ComponentType<P>,
): ComponentType<ExcludeAlertsProps<P>> {
  return function ComponentWithAlerts(props: ExcludeAlertsProps<P>) {
    return <ComposedComponent {...(props as P)} alerts={useContext(AlertsContext)} />;
  };
}
