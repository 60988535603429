import axios, {parseAxiosErrorMessage} from '@/utils/axios';
import ReactInterval from 'react-interval';
import type {ComponentType, ReactNode} from 'react';
import {Component, createContext, useContext} from 'react';
import {withRouter} from 'next/router';
import type {WithRouterProps} from 'next/dist/client/with-router';
import {User, UserRole} from '@soubul/ts-commons';
import {Nullish} from '@soubul/ts-commons/dist/es/types';

export interface GoogleLoginProps {
  readonly name: string;
  readonly email: string;
  readonly avatar_url: string;
  readonly id_token: string;
}

interface AuthContextProps {
  readonly user: Nullish<User>;
  readonly loading: boolean;
  setUser(user: Nullish<User>): Promise<void>;
  getCurrentUser(): Promise<void>;
  getUserByPhoneNumberAndUid(phoneNumber: string, uid: string): Promise<void>;
  loginByGoogleAccount(props: GoogleLoginProps): Promise<void>;
  signOut(): Promise<void>;
}

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  loading: true,
  setUser: Promise.resolve,
  getCurrentUser: Promise.resolve,
  getUserByPhoneNumberAndUid: Promise.resolve,
  loginByGoogleAccount: Promise.resolve,
  signOut: Promise.resolve,
});

type Props = WithRouterProps & {
  children: ReactNode;
};

type State = {
  user: Nullish<User>;
  loading: boolean;
};

class AuthProviderClass extends Component<Props, State> {
  public readonly state: State = {user: null, loading: true};

  public async componentDidMount(): Promise<void> {
    await this.getCurrentUser();
  }

  public render(): JSX.Element {
    return (
      <AuthContext.Provider
        value={{
          loading: this.state.loading,
          user: this.state.user,
          setUser: this.setUser,
          getCurrentUser: this.getCurrentUser,
          getUserByPhoneNumberAndUid: this.getUserByPhoneNumberAndUid,
          loginByGoogleAccount: this.loginByGoogleAccount,
          signOut: this.signOut,
        }}
      >
        {this.props.children}
        <ReactInterval timeout={1000 * 60 * 10} enabled={!!this.state.user} callback={this.refreshToken} />
      </AuthContext.Provider>
    );
  }

  private setUser = async (user: Nullish<User>) => {
    this.setState({user, loading: false});
    if (user) {
      if (typeof localStorage !== 'undefined' && user.accessToken) {
        localStorage.setItem('tk', user.accessToken);
      }
      if (user.role === UserRole.Provider) {
        if (!this.props.router.pathname.startsWith('/provider')) {
          await this.props.router.push({
            pathname: user.signupCompleted ? `/provider/${user.username}` : '/provider/onboard',
          });
        }
      } else {
        if (!this.props.router.pathname.startsWith('/user')) {
          await this.props.router.push({pathname: '/user/profile'});
        }
      }
    }
  };

  private getUserByPhoneNumberAndUid = async (phone_number: string, uid: string): Promise<void> => {
    try {
      this.setState({loading: true});
      const res = await axios.post('/auth/login/phone-number', {phone_number, uid});
      if (res.data) {
        await this.setUser(User.fromJSON(res.data));
      }
    } catch (e) {
      console.log(e);
      await this.setUser(null);
    }
  };

  private loginByGoogleAccount = async (props: GoogleLoginProps) => {
    this.setState({loading: true});
    const res = await axios.post('/auth/login/google', props);
    await this.setUser(User.fromJSON(res.data));
  };

  private getCurrentUser = async (): Promise<void> => {
    try {
      this.setState({loading: true});
      const tk = typeof localStorage !== 'undefined' && localStorage.getItem('tk');
      if (typeof tk === 'string') {
        const res = await axios.get('/auth/user-data');
        if (res.data) {
          await this.setUser(User.fromJSON(res.data));
        }
      }
    } catch (e) {
      if (parseAxiosErrorMessage(e).code === 'auth/token-expired') {
        await this.refreshToken();
        return this.props.router.reload();
      }
      await this.setUser(null);
    }
  };

  private refreshToken = async () => {
    try {
      const res = await axios.get('/auth/refresh-token');
      if (typeof localStorage !== 'undefined' && res.data.access_token) {
        localStorage.setItem('tk', res.data.access_token);
      }
    } catch (e) {
      await this.signOut();
    }
  };

  private signOut = async () => {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem('tk');
    }
    await this.setUser(null);
  };
}

export const AuthProvider = withRouter(AuthProviderClass);

export const useAuth = () => useContext(AuthContext);

export interface WithAuthProps {
  readonly session: AuthContextProps;
}

type ExcludeAuthProps<P> = Pick<P, Exclude<keyof P, keyof WithAuthProps>>;

export function withAuthentication<P extends WithAuthProps>(
  ComposedComponent: ComponentType<P>,
): ComponentType<ExcludeAuthProps<P>> {
  return function ComponentWithAuthentication(props: ExcludeAuthProps<P>) {
    return <ComposedComponent {...(props as P)} session={useAuth()} />;
  };
}

/**
 * An alias for the function {withAuthentication}.
 */
export const withAuth = withAuthentication;
